.table_div {
    text-align: center;
    overflow-y: auto;
    font-size: 0.8em;
}

.table_div thead th {
    position: sticky;
    top: 0;
    background: #fff;
    border-top: 0px;
}

.main_table {
    height: calc(100vh - 14rem);
    min-height: 300px;
}

.cursor_pointer {
    cursor: pointer;
}