html {
  height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  flex: 1;
  margin-top: 4rem;
  margin-bottom: 1rem;
  min-height: calc(100vh - 10rem);
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.logo {
  width: 30%;
  margin-top: 4rem;
  transition: width 2s ease;
}

.slogan {
  font-size: 1.125rem;
}

.vertical-down {
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.strategy-title {
  font-size: 1.4rem;
  font-weight: bold;
}

.social-icon {
  width: 3%;
  transition: width 2s ease;
}

.text-purple {
  color: #982ed1;
}
