.rank_div {
    min-height: 240px;
    margin-bottom: 10px;
}

.table_div {
    text-align: center;
    overflow-y: auto;
    font-size: 0.9em;
}

.table_div thead th {
    position: sticky;
    top: 0;
    background: #fff;
    border-top: 0px;
}