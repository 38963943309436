canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.chartlink {
    font-size: 1em;
}

.chart {
    max-height: 200px;
}

.indexchart {
    max-height: 150px;
}